<template>
  <div>
    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <b-card-header class="pb-50">
        <h5 class="text-capitalize">
          <span v-if="!isUpdating">{{ $t("video_watermarks.action_add") }}</span>
          <span v-else>{{ $t("video_watermarks.action_update") }}</span>
        </h5>
      </b-card-header>
      <b-card-body>
        <div class="mx-2 mb-2">
          <validation-observer ref="refFormObserver">
            <div class="m-2">
              <!-- ===================================== -->
              <b-row class="mb-2">
                <b-col md="12" class="mb-2">
                  <h3>{{ $t("video_watermarks.title_main") }}</h3>
                </b-col>

                <b-col class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    name="Watermark name"
                    rules="required"
                  >
                    <label class="d-block">{{ $t("video_watermarks.fields.name.label") }}</label>
                    <b-form-input
                      v-model="videoWatermarkName"
                      class="d-inline-block mr-1"
                      :placeholder="$t('video_watermarks.fields.name.placeholder')"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <b-col class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    name="Tenant"
                    rules="required"
                  >
                    <label class="d-block" for="tenant">
                      {{ $t("video_watermarks.fields.tenant.label") }}
                    </label>
                    <v-select
                      v-model="selectedTenant"
                      :options="tenantOptions"
                      :clearable="false"
                      input-id="tenant"
                      class="w-100"
                      :reduce="(val) => val.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- ===================================== -->
              <b-row>
                <b-col md="12" class="mb-3">
                  <hr class="mb-1" />
                  <h3>{{ $t("video_watermarks.title_location") }}</h3>
                </b-col>

                <b-col md="12">
                  <b-row>
                    <b-col md="3">
                      <!-- ============== Upload image ============== -->
                      <div class="upload-image mb-3">
                        <p class="lead">{{ $t("video_watermarks.fields.image.label") }}</p>
                        <vue-dropzone
                          id="dropzoneVideoWatermark"
                          ref="dropzoneVideoWatermark"
                          :options="dropzoneOptions"
                          class="dropzone"
                          :include-styling="false"
                          @vdropzone-file-added="addFile"
                          @vdropzone-removed-file="removeFile"
                          @vdropzone-thumbnail="thumbnailReady"
                        />
                        <div
                          id="containerImage"
                          class="hidden container-video-watermark droppable-zone"
                          ref="containerImage"
                        ></div>
                        <a
                          href="#"
                          class="d-block mt-1 text-danger"
                          v-if="filesCount > 0"
                          @click="removeFile()"
                        >
                          {{ $t("video_watermarks.btns.delete_image") }}
                        </a>
                      </div>
                      <div class="image-opacity mb-3 w-75" v-if="filesCount > 0">
                        <p class="lead">{{ $t("video_watermarks.fields.opacity.label") }}: {{ imageOpacity }}%</p>
                        <b-form-input
                          id="image-opacity"
                          v-model="imageOpacity"
                          type="range"
                          min="0"
                          max="100"
                        ></b-form-input>
                      </div>

                      <!-- ============== Text lines ============== -->
                      <div class="text-lines mb-3">
                        <p class="lead">{{ $t("video_watermarks.subtitle_1") }}</p>
                        <div
                          style="max-width: 350px"
                          class="mb-1"
                          v-for="index in 4"
                          :key="index"
                        >
                          <label class="d-block">{{ $t("video_watermarks.fields.text_lines.label") }}{{ index }}</label>
                          <v-select
                            v-model="textLines[`line${index}`]"
                            :options="placeholders"
                            :clearable="false"
                            taggable
                            multiple
                            :input-id="`line${index}`"
                            :create-option="(text) => ({ label: text, value: 'TEXT' })"
                            class="w-100"
                            :disabled="imagePositionSelected.value === 'GRID'"
                          />
                        </div>
                      </div>

                      <!-- ============== Positioning ============== -->
                      <div class="text-lines">
                        <p class="lead">{{ $t("video_watermarks.subtitle_2") }}</p>
                        <div style="max-width: 350px" class="mb-2" v-if="filesCount > 0">
                          <label class="d-block">{{ $t("video_watermarks.fields.image_pos.label") }}</label>
                          <v-select
                            v-model="imagePositionSelected"
                            :options="imagePositionsFiltered"
                            :clearable="false"
                            input-id="image-position"
                            class="w-100"
                          />
                        </div>

                        <div style="max-width: 350px" class="mb-2">
                          <label class="d-block">{{ $t("video_watermarks.fields.text_pos.label") }}</label>
                          <v-select
                            v-model="textPositionSelected"
                            :options="textPositionsFiltered"
                            :clearable="false"
                            input-id="text-position"
                            class="w-100"
                            :disabled="imagePositionSelected.value === 'GRID'"
                          />
                        </div>
                      </div>
                    </b-col>

                    <b-col md="9">
                      <div class="video-preview">
                        <div
                          :class="[
                            'video-layout',
                            { 'grid-layout': imagePositionSelected.value === 'GRID' },
                          ]"
                        >
                          <template v-if="imagePositionSelected.value !== 'GRID'">
                            <div
                              v-for="(pos, index) in positions"
                              :key="index"
                              :class="`corner-items ${pos.value}`"
                            >
                              <img
                                v-if="
                                  imageSrc && imagePositionSelected.value === pos.value
                                "
                                :src="imageSrc"
                                :style="`opacity: ${imageOpacity / 100}`"
                              />
                              <span
                                v-if="textPositionSelected.value === pos.value"
                                v-html="textLinesPreview"
                              ></span>
                            </div>
                          </template>
                          <template v-else>
                            <div class="grid-item" v-for="index in 30" :key="index">
                              <img
                                v-if="imageSrc"
                                :src="imageSrc"
                                :style="`opacity: ${imageOpacity / 100}`"
                              />
                            </div>
                          </template>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </validation-observer>
          <b-row>
            <b-col md="12" class="d-flex justify-content-end">
              <b-button variant="success" @click="save">
                <span v-if="!isUpdating">{{ $t("video_watermarks.btns.create") }}</span>
                <span v-else>{{ $t("video_watermarks.btns.update") }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import useVideoWatermarkConfig from "./useVideoWatermarkConfig";
import LayoutsWidget from "../components/GridView/widgets/LayoutsWidget.vue";
import PreviewLayout from "./layout/PreviewLayout.vue";
import vue2Dropzone from "vue2-dropzone";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    vSelect,
    vueDropzone: vue2Dropzone,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    LayoutsWidget,
    PreviewLayout,
  },
  setup(_, context) {
    return { ...useVideoWatermarkConfig() };
  },
};
</script>

<style lang="scss" scoped>
.dropzone {
  &.dz-drag-hover {
    background-color: rgba(#bee3f8, 0.3);
  }
  &#dropzoneVideoWatermark {
    &.hidden {
      display: none;
    }
    border: 1px dashed;
    max-width: 250px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.container-video-watermark {
  max-width: 250px;
}
.video-preview {
  width: 100%;
  height: 70%;
  background-color: #bee3f8;
  .video-layout {
    position: relative;
    width: 100%;
    height: 100%;
    &.grid-layout {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-content: space-around;
    }
    .corner-items {
      position: absolute;
      width: 350px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
      span {
        color: #fff;
        text-shadow: 1px 1px #000;
        font-size: 16px;
      }
      &.TOP_LEFT {
        top: 20px;
        left: 20px;
      }
      &.TOP_RIGHT {
        top: 20px;
        right: 20px;
      }
      &.BOTTOM_LEFT {
        bottom: 20px;
        left: 20px;
      }
      &.BOTTOM_RIGHT {
        bottom: 20px;
        right: 20px;
      }
    }
    .grid-item {
      display: flex;
      margin: 5px;
      width: calc(100% / 7);
      height: 100px;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }
  }
}
</style>
