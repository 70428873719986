import { useToast } from "vue-toastification/composition";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import {
  computed,
  onMounted,
  ref,
  watch,
  reactive,
} from "@vue/composition-api";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { image } from "vee-validate/dist/rules";

export default function useVideoWatermarkConfig() {

  const isUpdating = ref(false);
  const idVideoWatermark = ref(null);
  const videoWatermarkName = ref("");
  const tenants = ref([]);
  const tenantOptions = ref([]);
  const selectedTenant = ref(null);
  const toast = useToast();
  const { t } = useI18nUtils();
  const trans = {
    'video_watermarks.fields.image.hint': t('video_watermarks.fields.image.hint'),
    'video_watermarks.placeholders_options.ph1': t('video_watermarks.placeholders_options.ph1'),
    'video_watermarks.placeholders_options.ph2': t('video_watermarks.placeholders_options.ph2'),
    'video_watermarks.placeholders_options.ph3': t('video_watermarks.placeholders_options.ph3'),
    'video_watermarks.placeholders_options.ph4': t('video_watermarks.placeholders_options.ph4'),
    'video_watermarks.placeholders_options.ph5': t('video_watermarks.placeholders_options.ph5'),
    'video_watermarks.position_options.pos1': t('video_watermarks.position_options.pos1'),
    'video_watermarks.position_options.pos2': t('video_watermarks.position_options.pos2'),
    'video_watermarks.position_options.pos3': t('video_watermarks.position_options.pos3'),
    'video_watermarks.position_options.pos4': t('video_watermarks.position_options.pos4'),
    'video_watermarks.messages.success_created': t('video_watermarks.messages.success_created'),
    'video_watermarks.messages.success_updated': t('video_watermarks.messages.success_updated'),
  };

  const { refFormObserver, getValidationState } = formValidation();

  const dropzoneVideoWatermark = ref(null);
  const template = `
  <div class="template">
    <div class="dz-preview dz-file-preview" id="plano">
      <div id="imageContainer" class="droppable-zone" style="transform-origin: top left; width: fit-content; position: relative;">
        <img class="w-100" data-dz-thumbnail />
      </div>
    </div>
  </div>
`;
  const dropzoneOptions = ref({
    url: 'test',
    paramName: "plane",
    acceptedFiles: 'image/*',
    autoProcessQueue: false,
    uploadMultiple: false,
    thumbnailMethod: 'contain',
    previewsContainer: "#containerImage",
    thumbnailWidth: null,
    thumbnailHeight: null,
    previewTemplate: template,
    headers: {
      Authorization: `Bearer ${localStorage.accessToken}`
    },
    accept: (file, done) => {
      if (filesCount.value > 0) done();
    },
    dictDefaultMessage: trans['video_watermarks.fields.image.hint']
  });
  const cancel = ref(false);
  const filesCount = ref(0);
  const containerImage = ref(null);
  const imageOpacity = ref(50);
  const imageSrc = ref(null);

  const placeholders = [
    { label: trans['video_watermarks.placeholders_options.ph1'], value: 'UUID' },
    { label: trans['video_watermarks.placeholders_options.ph2'], value: 'CURRENT_DATE' },
    { label: trans['video_watermarks.placeholders_options.ph3'], value: 'CURRENT_TIME' },
    { label: trans['video_watermarks.placeholders_options.ph4'], value: 'USER_NAME' },
    { label: trans['video_watermarks.placeholders_options.ph5'], value: 'USER_EMAIL' },
  ];

  const textLines = reactive({
    line1: [],
    line2: [],
    line3: [],
    line4: [],
  })

  const textLinesPreview = computed(() => {
    return `
      ${textLines.line1.map(line => resolvePlaceholderValue(line)).join(' ')} <br>
      ${textLines.line2.map(line => resolvePlaceholderValue(line)).join(' ')} <br>
      ${textLines.line3.map(line => resolvePlaceholderValue(line)).join(' ')} <br>
      ${textLines.line4.map(line => resolvePlaceholderValue(line)).join(' ')} <br>
    `;
  });

  const positions = [
    { label: trans['video_watermarks.position_options.pos1'], value: 'TOP_LEFT' },
    { label: trans['video_watermarks.position_options.pos2'], value: 'TOP_RIGHT' },
    { label: trans['video_watermarks.position_options.pos3'], value: 'BOTTOM_LEFT' },
    { label: trans['video_watermarks.position_options.pos4'], value: 'BOTTOM_RIGHT' },
  ];

  const imagePositions = positions.concat([{ label: t('video_watermarks.position_options.pos5'), value: 'GRID' }]);

  const textPositions = [].concat(positions);

  const imagePositionSelected = ref({});
  const textPositionSelected = ref({});

  const imagePositionsFiltered = computed(() => {
    if (!textPositionSelected.value) {
      return imagePositions;
    }
    return imagePositions.filter(opt => opt.value !== textPositionSelected.value.value);
  });

  const textPositionsFiltered = computed(() => {
    return textPositions.filter(opt => opt.value !== imagePositionSelected.value.value);
  });

  // Methods ------------------------------------

  const loadInitData = async () => {
    const tenants = await axios.get("v1/tenant");
    tenantOptions.value = tenants.data.data.map((tenant) => ({
      value: tenant.id,
      label: tenant.name,
    }));
  };

  const loadUpdateData = async (idVideoWatermark) => {

    const { data } = await axios.get(`v1/services/video/watermark/${idVideoWatermark}`);
    const response = data.data;

    videoWatermarkName.value = response.name;
    selectedTenant.value = response.tenant.id;
    if (response.media) {
      const file = { ...response.media };
      dropzoneVideoWatermark.value.manuallyAddFile(file, response.media.url);
      filesCount.value = 1;
    }

    const config = response.config;

    imagePositionSelected.value = config.image_position || {};
    textPositionSelected.value = config.text_position || {};
    imageOpacity.value = config.image_opacity;
    Object.assign(textLines, config.text_lines)
  };

  const addFile = (file) => {
    document.querySelector("#dropzoneVideoWatermark").classList.add("hidden")
    document.querySelector("#containerImage").classList.remove("hidden")
    cancel.value = true
    filesCount.value = 1;
  }

  const removeFile = () => {
    document.querySelector("#dropzoneVideoWatermark").classList.remove("hidden")
    document.querySelector("#containerImage").classList.add("hidden")
    dropzoneVideoWatermark.value.removeAllFiles();
    cancel.value = false
    filesCount.value = 0;
    imageSrc.value = null;
  }

  const thumbnailReady = (file, dataURL) => {
    imageSrc.value = dataURL;
  }

  const resolvePlaceholderValue = (line) => {

    switch (line.value) {
      case 'UUID':
        return '123e4567-e89b-12d3-a456-426614174000';

      case 'CURRENT_DATE':
        return moment().format('YYYY-MM-DD');

      case 'CURRENT_TIME':
        return moment().format('HH:mm:ss');

      case 'USER_NAME':
        return 'John Doe';

      case 'USER_EMAIL':
        return 'john.doe@ejemplo.mx';

      case 'TEXT':
        return line.label;

      default:
        return '-';
    }

  }

  const save = async () => {
    const validation = await refFormObserver.value.validate();

    if (validation) {
      const isGridOptionSelected = imagePositionSelected.value.value === 'GRID';

      const data = new FormData();
      const config = {};

      if (filesCount.value > 0) {
        config['image_position'] = imagePositionSelected.value;
        config['image_opacity'] = imageOpacity.value;

        if (dropzoneVideoWatermark.value.getAcceptedFiles().length > 0) {
          data.append("image", dropzoneVideoWatermark.value.getAcceptedFiles()[0]);
        }
      } else {
        data.append("image", 'REMOVE_IMAGE');
      }

      if (!isGridOptionSelected) {
        config['text_position'] = textPositionSelected.value;
        config['text_lines'] = textLines
      }

      data.append("name", videoWatermarkName.value);
      data.append("id_tenant", selectedTenant.value);
      data.append("config", JSON.stringify(config));

      if (!isUpdating.value) {

        axios.post("v1/services/video/watermark", data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['video_watermarks.messages.success_created'],
              icon: "CheckIcon",
              variant: "success",
            },
          });

          isUpdating.value = true;
          idVideoWatermark.value = data.data.id;
          console.log(idVideoWatermark);
          router.push({ path: `/video-watermarks/config/${data.data.id}` });
        });

      } else {

        axios.post(`v1/services/video/watermark/${idVideoWatermark.value}`, data, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['video_watermarks.messages.success_updated'],
              icon: "CheckIcon",
              variant: "success",
            },
          });
        });

      }

    }
  };


  // Hooks ------------------------------------
  onMounted(async () => {

    await loadInitData();

    if (router.currentRoute.params.id_video_watermark) {
      isUpdating.value = true;
      idVideoWatermark.value = router.currentRoute.params.id_video_watermark
      loadUpdateData(idVideoWatermark.value);
    } else {
      store.commit('alarm_views/setLayoutItems', []);
    }

  });

  return {
    isUpdating,
    videoWatermarkName,

    tenants,
    tenantOptions,
    selectedTenant,

    refFormObserver,
    getValidationState,
    required,
    dropzoneOptions,
    dropzoneVideoWatermark,
    filesCount,
    containerImage,
    imageOpacity,
    imageSrc,

    save,
    addFile,
    removeFile,
    thumbnailReady,

    placeholders,
    textLines,
    textLinesPreview,

    positions,
    imagePositionsFiltered,
    imagePositionSelected,
    textPositionsFiltered,
    textPositionSelected,
  };
}
