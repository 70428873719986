<template>
  <div
    :class="[
      `grid-layout__item gl-w-${widget.w} gl-x-${widget.x}`,
      { 'slot-selection': slotSelection },
    ]"
    @click="onSelectSlot(widget.pos, widget)"
    @dblclick="unSetSlot(widget)"
  >
    <div class="grid-layout__item--content">
      <a
        href="#"
        class="remove-icon"
        v-if="slotItem.type"
        @click.prevent="unSetSlot(widget)"
      >
        <feather-icon icon="XCircleIcon" size="20" />
      </a>
      <p>
        {{ slotItem.name }}
        <template v-if="[SLOT_TYPES.CAM_LIVE, SLOT_TYPES.CAM_PA].includes(slotItem.type)">
          <br />
          {{ slotItem.data.camera.name }}
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import { SLOT_TYPES } from "@/config/layoutConfig";
import store from "@/store";

export default {
  components: {},
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    index: {
      type: Number,
    },
    widget: {
      type: Object,
      default: () => ({}),
    },
    slots: {
      type: Array,
    },
  },
  data() {
    return {
      SLOT_TYPES,
    };
  },
  computed: {
    slotItem() {
      return this.getSlotIfExists(this.slots[this.index]);
    },
    slotSelection() {
      return store.state.alarm_views.slotSelection;
    },
    slotData() {
      return store.state.alarm_views.slotData;
    },
    layoutItems() {
      return store.getters["alarm_views/layoutItems"];
    },
  },
  methods: {
    onSelectSlot(pos) {
      if (this.slotData) {
        const slot = this.findItem(this.slots, pos, "pos");
        let updatedSlot = null;

        slot.type = this.slotData.type;
        slot.name = this.slotData.name;
        slot.data = this.slotData.data;
        updatedSlot = Object.assign({}, slot);

        store.dispatch("alarm_views/resetSlotSelection");

        if (updatedSlot) {
          store.dispatch("alarm_views/addLayoutItem", updatedSlot);
        }
      }
    },
    unSetSlot(slot) {
      this.removeItemFromSlot(slot);
    },
    removeItemFromSlot(_slot) {
      const slot = this.findItem(this.slots, _slot.pos, "pos");
      let slotPos = 0;

      slot.type = null;
      slot.name = null;
      slot.data = {};
      slotPos = _slot.pos;

      if (slotPos > 0) {
        this.clearSlot(_slot.pos);
      }
    },
    clearSlot(pos) {
      store.dispatch("alarm_views/removeLayoutItem", pos);
    },
    findItem(data, id, prop = "id") {
      return data.find((item) => {
        if (item[prop] == id) return item;
      });
    },
    getSlotIfExists(_slot) {
      let slot = this.layoutItems.find((s) => _slot.pos === s.pos);
      return slot ? slot : _slot;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-layout__item {
  padding: 3px;
  cursor: pointer;
  border: none;
  &.slot-selection {
    &:hover {
      .grid-layout__item--content {
        background-color: #90d7ff;
      }
    }
  }
  .grid-layout__item--content {
    padding: 5px;
    .remove-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      color: red;
    }
    p {
      margin: 0;
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
}
</style>
